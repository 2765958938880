import { Link, usePage } from '@inertiajs/inertia-react';
import { mdiCellphone, mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import Tooltip from 'Components/Tooltip';
import useRole from 'Support/Hooks/useRole.js';
import cls from 'classnames';
import { ChevronBigDownIcon } from 'cool-icons';
import { useState } from 'react';
import LeftNavIcon from './LeftNavIcon';

const defaultClasses = 'group flex items-center rounded-md tracking-tight px-4 py-3 text-lg leading-6 overflow-x-hidden';
const stateClasses = 'focus:outline-none focus:text-white hover:text-white`';
const activeClasses = 'bg-nav-highlight bg-opacity-15 text-white';
const inactiveClasses = 'text-nav';
const noop = () => undefined;

const LeftNavMenu = ({ items, isCollapsed, isMobile = false, setSideBarOpen }) => {
  const getClasses = (item) => cls(defaultClasses, stateClasses, item.active ? activeClasses : inactiveClasses);
  const [open, setOpen] = useState(false);
  const {
    application: { pwa_url },
  } = usePage().props;
  const permissions = useRole();

  const closeNav = () => setSideBarOpen(false);

  return (
    <nav className="mt-12 space-y-4 px-2 focus:outline-none">
      {items.map((item, index) =>
        item.sub ? (
          <div key={index}>
            <Tooltip content={item.title} key={item.title} placement="right" disabled={!isCollapsed}>
              <div className={cls(getClasses(item), 'flex cursor-pointer')} onClick={() => setOpen(!open)}>
                <LeftNavIcon icon={item.icon} />
                <div className="flex w-full justify-between">
                  <span className={`ml-2 whitespace-nowrap transition-opacity duration-300 ease-in-out ${isCollapsed ? 'opacity-0' : 'opacity-100'}`}>
                    {item.title}
                  </span>
                  <ChevronBigDownIcon className={`h-6 w-6 transition-transform duration-700 ease-in-out ${open ? 'rotate-180' : ''}`} />
                </div>
              </div>
            </Tooltip>
            {item.sub &&
              open &&
              item.sub.map((subItem) => (
                <Tooltip content={subItem.title} key={subItem.href} placement="right" disabled={!isCollapsed}>
                  <Link href={subItem.href} onClick={isMobile ? closeNav : noop} className={cls(getClasses(subItem), 'ml-4')}>
                    <LeftNavIcon subIcon={true} icon={subItem.icon} active={subItem.active} />
                    <span
                      className={`ml-2 whitespace-nowrap text-base transition-opacity duration-700 ease-in-out ${!isCollapsed ? 'opacity-100' : 'opacity-0'}`}
                    >
                      {subItem.title}
                    </span>
                  </Link>
                </Tooltip>
              ))}
          </div>
        ) : (
          <Tooltip content={item.title} key={item.href} placement="right" disabled={!isCollapsed}>
            <Link href={item.href} onClick={isMobile ? closeNav : noop} className={getClasses(item)}>
              <LeftNavIcon icon={item.icon} active={item.active} />
              <span className={`ml-2 whitespace-nowrap transition-opacity duration-300 ease-in-out ${isCollapsed ? 'opacity-0' : 'opacity-100'}`}>
                {item.title}
              </span>
            </Link>
          </Tooltip>
        ),
      )}
      {isMobile && permissions.show_switch_pwa && (
        <Tooltip content="Return to PWA" placement="right">
          <button onClick={() => (window.location.href = pwa_url)} className={cls(defaultClasses, stateClasses, inactiveClasses)}>
            <LeftNavIcon icon={mdiCellphone} />
            <span className="ml-2 whitespace-nowrap transition-opacity duration-300 ease-in-out">Return to PWA</span>
            <Icon path={mdiOpenInNew} size={0.5} className="ml-2" />
          </button>
        </Tooltip>
      )}
    </nav>
  );
};

export default LeftNavMenu;
