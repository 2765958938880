import { Link } from '@inertiajs/inertia-react';
import cls from 'classnames';
import React from 'react';

const FlatButton = (
  {
    children,
    as,
    disabled = false,
    textColor = 'text-gray-900',
    borderColor = 'border-gray-500',
    stateClasses = 'hover:text-nav-highlight hover:border-primary focus-ring focus:text-nav-highlight',
    className = '',
    ...props
  },
  ref,
) => {
  const Element = as || (props.href ? Link : 'button');

  if (disabled) {
    stateClasses = 'text-opacity-25 cursor-default';
  }

  return (
    <Element
      ref={ref}
      className={cls(
        'transition transition-colors duration-300',
        'inline-block rounded border px-4 py-2.5 text-sm font-medium',
        stateClasses,
        className,
        textColor,
        borderColor,
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </Element>
  );
};

export default React.forwardRef(FlatButton);
