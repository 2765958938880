import { Menu } from '@headlessui/react';
import { Link } from '@inertiajs/inertia-react';
import { mdiOfficeBuilding } from '@mdi/js';
import Icon from '@mdi/react';
import MenuItem from 'Components/MenuItem';
import MenuItemsWrapper from 'Components/MenuItemsWrapper';
import useOrganization from 'Support/Hooks/useOrganization.js';
import useRole from 'Support/Hooks/useRole.js';
import useUser from 'Support/Hooks/useUser.js';
import cls from 'classnames';
import { LogOutIcon, Slider01Icon } from 'cool-icons';
import { useState } from 'react';

const OrganizationButton = ({ href, active, organization }) => (
  <Link
    href={href}
    className={cls({
      'bg-gray-100 text-gray-900': active,
      'text-gray-900 hover:bg-gray-100': !active,
      'flex w-full items-center space-x-3 px-4 py-2 text-left text-sm leading-5': true,
    })}
  >
    <span className="relative inline-block shrink-0">
      <Icon path={mdiOfficeBuilding} size={1} className="h-5 w-5 text-gray-500" />
      <span className={cls(active ? 'bg-green-400' : 'bg-gray-300', 'absolute right-0 top-0 block h-2.5 w-2.5 rounded-full ring-2 ring-gray-100')} />
    </span>
    <span className="truncate">{organization.name}</span>
  </Link>
);

const AccountMenu = () => {
  const { avatar, name, organizations } = useUser();
  const permissions = useRole();
  const organization = useOrganization();
  const [trigger, setTriggerElement] = useState(null);

  return (
    <div className="relative inline-block shrink-0 text-left">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button ref={setTriggerElement} className="focus-ring max-w-lg rounded border border-gray-500 px-4 py-2 text-sm">
              <div className="flex max-w-full items-center text-base sm:space-x-3" id="user-menu" aria-label="User menu" aria-haspopup="true">
                {organization &&
                  (organization?.has_logo ? (
                    <img className="h-8 max-w-32" src={organization.logo_url} alt={`${organization.name} logo`} />
                  ) : (
                    <div className="hidden max-w-xs truncate text-gray-900 sm:block">{organization.name}</div>
                  ))}
                <img className="h-8 w-8 rounded-full" src={avatar} alt={`${name} avatar`} />
              </div>
            </Menu.Button>

            <MenuItemsWrapper show={open} trigger={trigger} width="w-64" grouped>
              {organizations.length > 1 && permissions.show_organization_switch && (
                <div className="max-h-64 overflow-y-auto overflow-x-hidden py-4">
                  <h4 className="px-3 pb-2 text-xs uppercase tracking-wider text-gray-900">Companies</h4>
                  {organizations.map((org) => (
                    <OrganizationButton
                      key={org.id}
                      href={route('organizations.switch', org.id)}
                      organization={org}
                      active={org.id === organization?.id}
                    />
                  ))}
                </div>
              )}
              <MenuItem icon={Slider01Icon} href={route('account.index')}>
                My Account
              </MenuItem>
              <MenuItem icon={LogOutIcon} href={route('logout')}>
                Log out
              </MenuItem>
            </MenuItemsWrapper>
          </>
        )}
      </Menu>
    </div>
  );
};

export default AccountMenu;
