import { Dialog, Transition } from '@headlessui/react';
import LeftNavFooter from 'Layouts/Partials/LeftNav/LeftNavFooter';
import LeftNavLogo from 'Layouts/Partials/LeftNav/LeftNavLogo';
import LeftNavMenu from 'Layouts/Partials/LeftNav/LeftNavMenu';
import { CloseSmallIcon } from 'cool-icons';
import { Fragment } from 'react';

const MobileNav = ({ items, sidebarOpen, setSidebarOpen }) => {
  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 flex lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute right-0 top-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-850"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <CloseSmallIcon className={`my-2 h-6 w-6 text-blue-850 transition-transform duration-700 ease-in-out`} />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex h-0 flex-1 flex-col overflow-y-auto bg-primary pb-4 pt-5 text-gray-300">
                <LeftNavLogo isCollapsed={false} />
                <LeftNavMenu isCollapsed={false} isMobile={true} items={items} setSideBarOpen={setSidebarOpen} />
                <LeftNavFooter isCollapsed={false} />
              </div>
            </div>
          </Transition.Child>
          <div className="w-14 shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default MobileNav;
