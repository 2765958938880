import Icon from '@mdi/react';
import cls from 'classnames';
import Tooltip from 'Components/Tooltip';

const PageHeaderAction = ({ as = 'button', children, className = '', title, icon, iconClasses = 'h-6 w-6 flex-shrink-0', ...props }) => {
  const Component = as;

  let iconIsReactComponent = typeof icon === 'function';
  const IconComponent = iconIsReactComponent ? icon : Icon;
  const iconProps = iconIsReactComponent ? { className: iconClasses } : { path: icon, size: 1 };

  return (
    <Tooltip content={title}>
      <Component className={cls('hover:text-pink focus:text-pink focus:outline-none', className)} {...props}>
        <IconComponent {...iconProps} />
      </Component>
    </Tooltip>
  );
};

export default PageHeaderAction;
