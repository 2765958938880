import { Link, usePage } from '@inertiajs/inertia-react';
import { mdiExclamationThick } from '@mdi/js';
import Icon from '@mdi/react';
import FlatButton from 'Components/Buttons/FlatButton';
import PageWidthConstraint from 'Components/PageWidthConstraint';
import useUser from 'Support/Hooks/useUser.js';

const Notice = ({ href, action, as, content, subContent, children }) => {
  const Element = as ? as : href ? Link : 'div';

  return (
    <Element href={href} className="bg-red text-white">
      <PageWidthConstraint className="flex items-center gap-4 py-4">
        <div className="rounded-full bg-white p-1 text-red">
          <Icon path={mdiExclamationThick} size={1} color="currentColor" />
        </div>
        <div className="flex grow flex-col gap-x-2">
          <div>{children || content}</div>
          {subContent && <p className="text-xs text-gray-100">{subContent}</p>}
        </div>
        {href && <FlatButton className="bg-white">{action}</FlatButton>}
      </PageWidthConstraint>
    </Element>
  );
};

const NoticeBar = () => {
  const user = useUser();
  const {
    props: {
      application: { harm_support_user },
    },
  } = usePage();

  if (user.id === parseInt(harm_support_user.id)) {
    return (
      <Notice content="CAUTION: Ghosting a company should only be used to add initial registers - all other additions should be made by ghosting a user" />
    );
  }
  return null;
};

export default NoticeBar;
