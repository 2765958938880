import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-react';
import { mdiGhostOff } from '@mdi/js';
import Icon from '@mdi/react';
import PageHeaderAction from 'Components/Buttons/PageHeaderAction';
import PageWidthConstraint from 'Components/PageWidthConstraint';
import NoticeBar from 'Layouts/Partials/NoticeBar';
import useInitialApplication from 'Support/Hooks/useInitialApplication.js';
import useUser from 'Support/Hooks/useUser.js';
import { HamburgerIcon, SearchIcon, WarningIcon } from 'cool-icons';
import AccountMenu from './AccountMenu';

const SearchBar = () => {
  const handleInputChange = (e) => Inertia.post(route('search'), { searchValue: e.target.value });

  return (
    <>
      <input
        type="search"
        onChange={handleInputChange}
        placeholder="Search"
        className="border-1 focus-ring w-1/3 min-w-60 rounded border-white py-2.5 pl-10 text-sm placeholder-gray-800 shadow"
        disabled
      />
      <SearchIcon className="absolute left-3 h-5 w-5 text-gray-800" />
    </>
  );
};

const ActionBar = ({ setSidebarOpen }) => {
  const { production } = useInitialApplication();
  const user = useUser();

  return (
    <>
      <NoticeBar />
      <PageWidthConstraint className="relative z-10 flex h-20 shrink-0 justify-between gap-4">
        {/*<div className="flex items-center relative">*/}
        {/*  <SearchBar/>*/}
        {/*</div>*/}
        <div className="flex items-center">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <HamburgerIcon className={`my-2 h-6 w-6 text-blue-850 transition-transform duration-700 ease-in-out`} />
          </button>
        </div>
        <div className="ml-auto flex items-center space-x-4">
          {/*<NotificationsMenu/>*/}

          {user.is_ghosting && (
            <Link href={route('admin.ghost.user.stop')} className="text-secondary">
              <Icon path={mdiGhostOff} size={1} color="currentColor" />
            </Link>
          )}

          {!production && (
            <PageHeaderAction
              as="a"
              href="#"
              className="h-8 w-8"
              iconClasses="h-7 w-7 hover:text-nav-highlight text-pink"
              icon={WarningIcon}
              title="Currently on Staging server"
            />
          )}
          <AccountMenu />
        </div>
      </PageWidthConstraint>
    </>
  );
};

export default ActionBar;
